import { getValidColor } from '@/libs/ColorHelper';
import { logger } from '@/libs/logger';
import AppConfig from '@config';
import { DecodeHtmlFormat, trackEvent } from '@libs';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Member, dispatchChatNotify, processError, useAppStore } from '@store';
import { useState } from 'react';
import {
  Dimensions,
  ImageBackground,
  Linking,
  StyleSheet,
  View,
} from 'react-native';
import { Button, Caption, Title } from 'react-native-paper';
import RenderHtml from 'react-native-render-html';
import Swiper from 'react-native-web-swiper';
import ReactPlayer from 'react-player';
import { StreamChat } from 'stream-chat';
import {
  GuidesContentsRouteParams,
  useGuidesContents,
} from '../../store/guides/hooks';
import Layout from '../components/Layout';
import Popup from '../components/Popup';
import RootContainer from '../components/RootContainer';

const { height } = Dimensions.get('window');

const Content = () => {
  const navigation = useNavigation();
  const [autoReplyPopup, setAutoReplyPopup] = useState(false);
  const { company, reservation, messagingToken, host } = useAppStore();
  const route = useRoute();
  const { selectedContent } = useGuidesContents(
    route.params as GuidesContentsRouteParams,
  );

  const guestName =
    reservation?.guestName ||
    `A Guest in unit '${reservation?.property?.name}' `;
  const popupMessage = selectedContent?.button?.autoreply_message ?? '';
  const message = `${guestName} has requested information on ${selectedContent?.button.subject}`;

  const handlePressButton = () => {
    const buttonData = selectedContent?.button;
    const isSendingMessage =
      company?.smartButtonMessengerEnabled && buttonData?.messenger_enabled;
    const isSendingMessageMPMessenger =
      company?.mpGuestMessengerEnabled && isSendingMessage;
    const isSendingMessageNoMPMessenger =
      !company?.mpGuestMessengerEnabled && isSendingMessage;

    if (isSendingMessageMPMessenger) {
      sendMessageOnMessenger();
      return;
    }

    if (isSendingMessageNoMPMessenger) {
      sendMessageNoInterface();
      return;
    }

    try {
      if (!buttonData?.hyperlink) {
        // TODO: navigate to 404 page
        throw new Error('No hyperlink found');
      } else {
        handleLinkClick(buttonData?.hyperlink);
      }
    } catch (error) {
      const msg = processError(error);
      logger.error(msg);
    }
  };

  const handleLinkClick = (hyperlink = '') => {
    trackEvent('Guides', 'Page>Smart Button:Clicked', {
      type: 'Link',
    });

    if (hyperlink.startsWith('tel:')) {
      return Linking.openURL(hyperlink);
    }

    if (hyperlink.includes('@')) {
      hyperlink = ['mailto:', hyperlink].join('');
    } else if (
      !hyperlink.startsWith('https://') &&
      !hyperlink.startsWith('http://')
    ) {
      hyperlink = ['https://', hyperlink].join('');
    }

    Linking.openURL(hyperlink);
  };

  const sendMessageOnMessenger = () => {
    trackEvent('Guides', 'Page>Smart Button:Clicked', {
      type: 'Messages',
    });

    navigation.navigate('Main', {
      screen: 'Messages',
      params: {
        text: message,
        autoReply: selectedContent?.button?.autoreply_message,
      },
    });
  };

  const sendMessageNoInterface = () => {
    let channel;
    const chatClient = StreamChat.getInstance(
      AppConfig.Settings.GET_STREAM_API_KEY as string,
    );
    const userToken = messagingToken?.token;
    const member = (host?.members as Member[]).find(Boolean);
    const people = member?.id;

    if (messagingToken?.id) {
      chatClient.connectUser(
        {
          id: messagingToken.id,
          name: reservation?.guestName || 'Guest',
          image: '',
        },
        userToken,
      );
      channel = chatClient.channel(
        `${messagingToken.channel_id.split(':')[0]}`,
        `${messagingToken.channel_id.split(':')[1]}`,
        {
          // add as many custom fields as you'd like
          image: `${host?.welcomeAvatar}`,
          name: `${member?.name}`,
          members: [`${people}`],
        },
      );

      channel.sendMessage({
        text: message,
      });
    }

    trackEvent('Guides', 'Page>Smart Button:Clicked', {
      type: 'Messages (No Interface)',
    });

    if (company) {
      dispatchChatNotify(message, company.id, company.reservation as string);
      setAutoReplyPopup(!autoReplyPopup);
    }
  };

  return (
    <RootContainer>
      <Layout>
        <View style={styles.contentContainer}>
          {selectedContent?.images_url && (
            <View testID="carousel" style={styles.carouselContainer}>
              <Swiper>
                {!!selectedContent?.video_url && (
                  <ReactPlayer
                    testID="video-player"
                    light
                    config={{
                      vimeo: {
                        playerOptions: { controls: true },
                      },
                    }}
                    url={selectedContent?.video_url}
                    width="100%"
                  />
                )}
                {selectedContent?.images_url.map(item_url => (
                  <View key={item_url} style={[styles.slideContainer]}>
                    <ImageBackground
                      testID="cover-image"
                      source={{
                        uri: `${item_url}?tr=w-${AppConfig.Styles.getWindowWidth()}`,
                      }}
                      resizeMode="cover"
                      style={styles.image}
                    />
                  </View>
                ))}
              </Swiper>
            </View>
          )}
          <View style={styles.container}>
            <Title
              testID="title"
              style={{ marginBottom: AppConfig.Styles.getAdjustedSize(20) }}>
              {DecodeHtmlFormat(selectedContent?.title)}
            </Title>
            <Caption
              style={[
                AppConfig.Styles.body2,
                {
                  marginBottom: AppConfig.Styles.getAdjustedSize(20),
                  lineHeight: 27,
                },
              ]}>
              {selectedContent?.description && (
                <RenderHtml
                  source={{ html: selectedContent?.description }}
                  tagsStyles={tagsStyles}
                  baseStyle={{ display: 'flex' }}
                />
              )}
            </Caption>
            {selectedContent?.button.enabled && (
              <Button
                mode="contained"
                onPress={handlePressButton}
                buttonColor={getValidColor(selectedContent?.button.color)}
                contentStyle={{ height: 48 }}
                labelStyle={{ fontSize: 16 }}
                style={{ borderRadius: 8 }}>
                {selectedContent?.button.title}
              </Button>
            )}
            <Popup
              visible={autoReplyPopup}
              onDismiss={() => setAutoReplyPopup(!autoReplyPopup)}
              text={popupMessage}
            />
          </View>
        </View>
      </Layout>
    </RootContainer>
  );
};

const tagsStyles = {
  p: {
    margin: '3px',
  },
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
  },
  container: {
    minHeight: height - AppConfig.Styles.getAdjustedSize(304),
    backgroundColor: '#fff',
    padding: AppConfig.Styles.getAdjustedSize(20),
    paddingBottom: AppConfig.Styles.getAdjustedSize(40),
  },
  carouselContainer: {
    height: AppConfig.Styles.getAdjustedSize(280),
  },
  slideContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: AppConfig.Styles.getAdjustedSize(280),
    width: '100%',
    justifyContent: 'center',
    objectFit: 'cover',
  },
});

export default Content;
