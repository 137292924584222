import { REGION } from '@/assets/configurations/Settings';
import {
  CognitoIdentityProviderClient,
  GetUserAttributeVerificationCodeCommand,
  GetUserAttributeVerificationCodeCommandOutput,
  UpdateUserAttributesCommand,
  UpdateUserAttributesCommandOutput,
  VerifyUserAttributeCommand,
  VerifyUserAttributeCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';

export const updateUserPhoneNumberAttribute = async (
  number: string,
  accessToken: string,
): Promise<UpdateUserAttributesCommandOutput> => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const input = {
    UserAttributes: [
      {
        Name: 'phone_number',
        Value: number,
      },
    ],
    AccessToken: accessToken,
  };

  const command = new UpdateUserAttributesCommand(input);

  return client.send(command);
};

export const sendPhoneNumberVerificationCode = async (
  accessToken: string,
): Promise<GetUserAttributeVerificationCodeCommandOutput> => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const input = {
    AccessToken: accessToken,
    AttributeName: 'phone_number',
  };

  const command = new GetUserAttributeVerificationCodeCommand(input);

  return client.send(command);
};

export const verifyPhoneNumber = async (
  code: string,
  accessToken: string,
): Promise<VerifyUserAttributeCommandOutput> => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const input = {
    AccessToken: accessToken,
    AttributeName: 'phone_number',
    Code: code,
  };

  const command = new VerifyUserAttributeCommand(input);
  return await client.send(command);
};
