import { display, name } from './app.json';

// App Generics
export const APP_NAME = name;
export const APP_DISPLAY_NAME = display;

// Environment Variables
export const BASE_API_URL = process.env.REACT_APP_OPERTO_API_URL;
export const BASE_PORTAL_URL = process.env.REACT_APP_OPERTO_PORTAL_URL;
export const COMPANY_CONFIG_ID = process.env.REACT_APP_OPERTO_CONFIG_ID;
export const REDUX_DB_SECRET = process.env.REACT_APP_OPERTO_DB_SECRET;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GET_STREAM_API_KEY = process.env.REACT_APP_GET_STREAM_API_KEY;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const OPERTO_TRPC_URL = process.env.REACT_APP_OPERTO_TRPC_URL;

export const GUEST_AUTH_USER_POOL_WEB_CLIENT_ID =
  process.env.REACT_APP_GUEST_AUTH_USER_POOL_WEB_CLIENT_ID;

export const GUEST_AUTH_COGNITO_DOMAIN =
  process.env.REACT_APP_GUEST_AUTH_COGNITO_DOMAIN;

export const REGION = process.env.REACT_APP_REGION;
export const GUEST_PORTAL_URL = process.env.REACT_APP_GUEST_PORTAL_URL;
export const GUEST_AUTH_FIXED_PASSWORD =
  process.env.REACT_APP_GUEST_AUTH_FIXED_PASSWORD;

export const GUEST_SERVICE_DOMAIN = process.env.REACT_APP_GUEST_SERVICE_DOMAIN;
export const GUEST_AUTH_DOMAIN = process.env.REACT_APP_GUEST_AUTH_DOMAIN;
