import { isProduction } from '@/libs/constants';
import { logger } from '@/libs/logger';
import AppConfig from '@config';
import * as FullStory from '@fullstory/browser';
import { AppProvider, dispatchReset } from '@store';
import { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { SnackBar } from '../components';
import { setRegistrationInfoToAsyncStorage } from '../login/LoginCognito/utils';
import { AppContainer } from './AppContainer';

export type ConfigType = {
  id: string;
  entryPath?: string;
  origin: string;
};

export type RootSnackbarState = {
  title: string;
  color: string;
  duration?: number;
};

const linking = (options: { id: string; origin: string }) => ({
  prefixes: [options.origin],
  config: {
    screens: {
      Reservations: `${options.id}/trips`,
      Home: `${options.id}/home`,
      Profile: `${options.id}/profile`,
      Places: `${options.id}/places`,
      Place: `${options.id}/place`,
      InstructionsDetail: `${options.id}/intructions`,
      Confirmation: `${options.id}/confirmation/:contentTitle`,
      Departure: `${options.id}/departure`,
      Messages: `${options.id}/messages`,
      HouseManual: `${options.id}/manual`,
      ManualDetail: `${options.id}/manual/detail`,
      SecurityDeposit: `${options.id}/security-deposit`,
      Verification: `${options.id}/verification`,
      Terms: `${options.id}/terms`,
      SignatureTerms: `${options.id}/v2/terms`,
      SectionsList: `${options.id}/sections-list`,
      Section: `${options.id}/sections-list/sections/:sectionId?`,
      Category: `${options.id}/sections-list/sections/:sectionId/categories/:categoryId`,
      Content: `${options.id}/sections-list/sections/:sectionId/categories/:categoryId/contents/:contentId`,
      QRCodeCheckIn: `${options.id}/room-type/checkin`,
      CheckInRequested: `${options.id}/room-type/checkin-inprogress`,
      CheckInConfirmation: `${options.id}/room-type/checkin-confirmation`,
      Auth: `${options.id}/auth`,
      VerifyOtp: `${options.id}/verify-otp`,
      CreateGuestProfile: `${options.id}/create-profile`,
      TermsConditions: `${options.id}/terms-conditions`,
      AddPhoneNumber: `${options.id}/add-phone-number`,
      VerifyPhoneNumber: `${options.id}/verify-phone-number`,
      LoginChallenge: `${options.id}/login/challenge`,
      LoginTerms: `${options.id}/login/terms`,
      LoginWelcome: `${options.id}/login/welcome`,
      LoginContact: `${options.id}/login/contact`,
      LoginVerification: `${options.id}/login/verification`,
      PhoneVerification: `${options.id}/login/verify-phone`,
      VerifyGuest: {
        screens: {
          Disclaimer: `${options.id}/verifications/disclaimer`,
          Details: `${options.id}/verifications/details`,
          Methods: `${options.id}/verifications/methods`,
          CaptureId: `${options.id}/verifications/CaptureId`,
          ConfirmId: `${options.id}/verifications/ConfirmId`,
          CaptureSelfie: `${options.id}/verifications/CaptureSelfie`,
          ConfirmSelfie: `${options.id}/verifications/ConfirmSelfie`,
          Submit: `${options.id}/verifications/Submit`,
        },
      },
      VerificationFlow: {
        screens: {
          Disclaimer: `${options.id}/login/verify/disclaimer`,
          Details: `${options.id}/login/verify/details`,
          Methods: `${options.id}/login/verify/methods`,
          CaptureId: `${options.id}/login/verify/CaptureId`,
          ConfirmId: `${options.id}/login/verify/ConfirmId`,
          CaptureSelfie: `${options.id}/login/verify/CaptureSelfie`,
          ConfirmSelfie: `${options.id}/login/verify/ConfirmSelfie`,
          Submit: `${options.id}/login/verify/Submit`,
          // Pending: `${options.id}/login/verify/pending`,
        },
      },
      NotFound: `${options.id}/*`,
    },
  },
});

export const Root = () => {
  const [urlOptions, setUrlOptions] = useState<ConfigType>({
    id: '',
    origin: '',
    entryPath: '',
  });

  const [rootSnackbarState, setRootSnackbarState] =
    useState<RootSnackbarState>();

  useEffect(() => {
    const getUrlOptions = async () => {
      if (urlOptions.origin.length) {
        return;
      }

      // TODO: clean this up, not suppose to be here in useEffect - [JO]
      if (isProduction) {
        FullStory.init({ orgId: '12TVFB' });
      }

      const options = {
        ...urlOptions,
        id: AppConfig.Settings.COMPANY_CONFIG_ID ?? '',
      };

      try {
        // Linking.getInitialURL() will only have value on initial launch or reload of web page
        const url = (await Linking.getInitialURL()) ?? '';
        const { origin, pathname, searchParams } = new URL(url);
        options.origin = origin;

        const configId = pathname?.split('/')?.[1];
        const noIdInPath = (configId?.split('_')?.length ?? 0) < 2;

        // a hack for vrscheduler OR the external ID that has @sign
        if (configId?.includes('%2540')) {
          window.location.replace(url.replace('%2540', '@'));
        }

        // remove the auth token if reset call has been made, we send the res id because in the store we don't have that info
        if (searchParams.get('r') === 'true') {
          await dispatchReset(configId?.split('_')?.[1]);
        }

        // Federated auth error handling
        if (searchParams.get('error_description')) {
          await setRegistrationInfoToAsyncStorage({
            error: searchParams.get('error_description'),
          });
        }

        options.id = configId;
        options.entryPath = pathname.replace(`/${configId}`, '');

        if (noIdInPath) {
          options.id = '';
          options.entryPath = configId ? `/${configId}` : '/auth';
        }
      } catch (error) {
        logger.error(error);
      }

      setUrlOptions(options);
    };

    getUrlOptions();
  }, [urlOptions]);

  return (
    <AppProvider
      config={urlOptions}
      setConfig={setUrlOptions}
      openSnackbar={setRootSnackbarState}>
      <AppContainer linking={linking(urlOptions)} />

      <SnackBar
        title={rootSnackbarState?.title ?? ''}
        visible={!!rootSnackbarState}
        color={rootSnackbarState?.color ?? ''}
        onDismiss={() => setRootSnackbarState(undefined)}
        duration={rootSnackbarState?.duration}
      />
    </AppProvider>
  );
};

export default Root;
