import { trackEvent } from '@/libs';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { Linking, StyleSheet, View } from 'react-native';
import { Avatar, FAB, Text, useTheme } from 'react-native-paper';

interface IAvatarImageProps {
  uri?: string;
  name?: string;
}

const getInitialsForName = (name = '') => {
  const words = name.split(' ');
  let initials = words[0].charAt(0);

  if (words.length > 1) {
    initials += words[1].charAt(0);
  }

  return initials.toUpperCase();
};

export const AvatarImage = ({ uri, name }: IAvatarImageProps) => {
  return (
    <View style={[styles.avatar]}>
      {uri ? (
        <Avatar.Image
          source={{ uri }}
          size={AppConfig.Styles.getAdjustedSize(40)}
        />
      ) : (
        <Avatar.Text
          label={getInitialsForName(name)}
          size={AppConfig.Styles.getAdjustedSize(40)}
        />
      )}
    </View>
  );
};

type HostDetailsProps = {
  name: string;
  phone?: string;
  email?: string;
  avatar?: string;
};

export const HostDetails = ({
  name,
  phone,
  email,
  avatar,
}: HostDetailsProps) => {
  const { t } = useLocalization();
  const theme = useTheme();

  return (
    <View style={[AppConfig.Styles.border, styles.borderContainer]}>
      <AvatarImage uri={avatar} name={name} />
      <View
        style={{
          marginHorizontal: AppConfig.Styles.getAdjustedSize(8),
          flex: 1,
        }}>
        <Text variant="titleMedium" numberOfLines={1}>
          {name}
        </Text>
        <Text variant="bodySmall">{t('booking_host_details')}</Text>
      </View>
      {phone && (
        <FAB
          style={[styles.fab, { backgroundColor: theme.colors.primary }]}
          color="white"
          icon="phone"
          onPress={() => {
            Linking.openURL(`tel:${phone}`);
            trackEvent('Menu', 'Host Phone:Clicked');
          }}
          testID="host-phone-fab"
        />
      )}
      {email && (
        <FAB
          style={[
            styles.fab,
            {
              backgroundColor: theme.colors.primary,
              marginLeft: phone
                ? AppConfig.Styles.getAdjustedSize(16)
                : AppConfig.Styles.getAdjustedSize(0),
            },
          ]}
          color="white"
          icon="email"
          onPress={() => {
            Linking.openURL(`mailto:${email}`);
            trackEvent('Menu', 'Host Email:Clicked');
          }}
          testID="host-email-fab"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  avatar: {
    width: AppConfig.Styles.getAdjustedSize(40),
    height: AppConfig.Styles.getAdjustedSize(40),
  },
  borderContainer: {
    flexDirection: 'row',
    marginHorizontal: AppConfig.Styles.getAdjustedSize(16),
    padding: AppConfig.Styles.getAdjustedSize(16),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fab: {
    height: AppConfig.Styles.getAdjustedSize(40),
    width: AppConfig.Styles.getAdjustedSize(40),
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default HostDetails;
