import { logger } from '@/libs/logger';
import {
  getCognitoIdTokenFromAsyncStorage,
  getTokenWithRefreshToken,
  isCognitoTokenStillValid,
  removeCognitoAccessTokenFromAsyncStorage,
  signOutUser,
} from '@/screens/login/LoginCognito/utils';
import AppConfig from '@config';
import { isTimestampEarlierThanNow } from '@libs';
import CryptoJS from 'crypto-js';
import { setToken } from '../auth';
import { AppState, ChatToken, Company, Reservation } from '../models/models';
import { store } from '../store';
import { CHATTOKEN, COMPANIES, RESERVATIONS, RESET } from './actions';

const shouldForceLogout = () => {
  const appState: AppState = store.getState().guestportal;
  const company = Object.values(appState?.companies).find(Boolean);
  const reservationId = company?.reservation as string;

  return company?.challengePassed && isAccessTokenValid(reservationId);
};

const getLocalStorageAuth = (reservationId: string) => {
  try {
    /**
     * NOTE: observe if encrypt/decrypt causes noticable slowness.
     * we need to cache in memory if it does.
     */
    const localTokenString = localStorage.getItem(reservationId);
    if (!localTokenString) {
      return {};
    }

    const bytes = CryptoJS.AES.decrypt(
      localTokenString,
      AppConfig.Settings.REDUX_DB_SECRET ?? '',
    );

    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    logger.error(err);

    return {};
  }
};

const getLocalStorageForKey = (
  reservationId: string,
  key: 'expiry' | 'token',
) => getLocalStorageAuth(reservationId)[key];

export const dispatchCompanies = (models: Company[], removeIds?: string[]) =>
  store.dispatch({
    type: COMPANIES,
    data: models,
    deletedIds: removeIds,
  });

export const dispatchReservations = (
  models: Reservation[],
  removeIds?: string[],
) =>
  store.dispatch({
    type: RESERVATIONS,
    data: models,
    deletedIds: removeIds,
  });

export const dispatchChatToken = (models: ChatToken[], removeIds?: string[]) =>
  store.dispatch({
    type: CHATTOKEN,
    data: models,
    deletedIds: removeIds,
  });

export const dispatchReset = async (resId?: string) => {
  const appState: AppState = store.getState().guestportal;
  const company = Object.values(appState?.companies).find(Boolean);
  const reservationId = (company?.reservation as string) ?? resId;

  if (reservationId) {
    localStorage.removeItem('InitialToken' + reservationId);
    localStorage.removeItem(reservationId);
  }

  await signOutUser();
  await removeCognitoAccessTokenFromAsyncStorage();

  await store.dispatch({
    type: RESET,
  });
};

export const processError = (error: unknown) => {
  if (error === undefined || error === null) {
    return 'Error: unknown';
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  try {
    return JSON.stringify(error);
  } catch {
    return 'Error: processing given error';
  }
};

export const processResponse = async (response: Response) => {
  if (shouldForceLogout() && response.status === 401) {
    return await dispatchReset();
  }

  if (!response.ok) {
    let errMsg = response.statusText;
    try {
      const { message = '', errors = [] } = await response.json();
      if (message.length) {
        errMsg = errMsg?.length ? `${errMsg} > ${message}` : message;
      }

      if (errors.length) {
        errMsg = errMsg.length
          ? `${errMsg} > ${errors.shift()}`
          : errors.shift();
      }
    } catch (e) {
      logger.error('error processing response: ', e);
    }

    throw new Error(`${response.status} - ${errMsg}`);
  }

  return await response?.json();
};

export const validateCompany = (data?: {
  reservation_match: boolean;
  guest_portal_enabled?: boolean;
}) => {
  if (data === undefined || data === null) {
    return false;
  }

  if (data.guest_portal_enabled !== undefined && !data.guest_portal_enabled) {
    return false;
  }

  if (!data.reservation_match) {
    return false;
  }

  return true;
};

export const getAccessToken = async () => {
  const appState: AppState = store.getState().guestportal;
  const company = Object.values(appState?.companies).find(Boolean);
  const reservationId = company?.reservation as string;
  const challengePassed = company?.challengePassed;

  let accessToken = await getCognitoIdTokenFromAsyncStorage();

  if (accessToken) {
    if (!(await isCognitoTokenStillValid())) {
      logger.debug('Token expired, refreshing token');
      accessToken = (await getTokenWithRefreshToken()).idToken;
      logger.debug('New Token' + accessToken);
    }

    if (accessToken) {
      store.dispatch(setToken({ idToken: accessToken, tokenType: 'AWS' }));
      return { token: accessToken, type: 'AWS' };
    }
  }

  if (challengePassed && isAccessTokenValid(reservationId)) {
    const reservationToken = getLocalStorageForKey(reservationId, 'token');
    store.dispatch(setToken({ idToken: reservationToken, tokenType: 'OAUTH' }));
    return {
      token: reservationToken,
      type: 'OAUTH',
    };
  }

  if (company?.accessToken?.length) {
    store.dispatch(
      setToken({ idToken: company.accessToken, tokenType: 'OAUTH' }),
    );
    return { token: company.accessToken, type: 'OAUTH' };
  }

  throw new Error('Invalid Access Token');
};

export const setLocalStorageForKey = (
  reservationId: string,
  key: 'expiry' | 'token',
  value: string,
) => {
  const localObject = getLocalStorageAuth(reservationId);
  localObject[key] = value;

  const data = CryptoJS.AES.encrypt(
    JSON.stringify(localObject),
    AppConfig.Settings.REDUX_DB_SECRET ?? '',
  ).toString();

  localStorage.setItem(reservationId, data);
};

export const isAccessTokenValid = (reservationId: string) => {
  const expiryTime = getLocalStorageForKey(reservationId, 'expiry');
  if (!expiryTime || isTimestampEarlierThanNow(parseInt(expiryTime, 10))) {
    localStorage.removeItem(reservationId);
    return false;
  }

  const initialExpiryTime = getLocalStorageForKey(
    'InitialToken' + reservationId,
    'expiry',
  );
  if (
    !initialExpiryTime ||
    isTimestampEarlierThanNow(parseInt(initialExpiryTime, 10))
  ) {
    localStorage.removeItem('InitialToken' + reservationId);
    return false;
  }

  return true;
};

/** Initial token functions. The initial token is the only one with access end point to get information about the company. */

const getLocalStorageAuthForInitialToken = (reservationId: string) => {
  try {
    /**
     * NOTE: observe if encrypt/decrypt causes noticable slowness.
     * we need to cache in memory if it does.
     */
    const localTokenString = localStorage.getItem(
      'InitialToken' + reservationId,
    );

    if (!localTokenString) {
      return {};
    }

    const bytes = CryptoJS.AES.decrypt(
      localTokenString,
      AppConfig.Settings.REDUX_DB_SECRET ?? '',
    );

    const decryptedAccessTokenObject = JSON.parse(
      bytes.toString(CryptoJS.enc.Utf8),
    );

    if (decryptedAccessTokenObject.expiry && decryptedAccessTokenObject.token) {
      return decryptedAccessTokenObject;
    }

    return {};
  } catch (err) {
    logger.error(err);

    return {};
  }
};

export const setLocalStorageForInitialToken = (
  reservationId: string,
  key: 'expiry' | 'token',
  value: string,
) => {
  const localObject = getLocalStorageAuthForInitialToken(reservationId);
  localObject[key] = value;

  const data = CryptoJS.AES.encrypt(
    JSON.stringify(localObject),
    AppConfig.Settings.REDUX_DB_SECRET ?? '',
  ).toString();

  localStorage.setItem('InitialToken' + reservationId, data);
};

export const getAccessTokenForInitialToken = (reservationId: string) => {
  const accessToken = getLocalStorageAuthForInitialToken(reservationId);

  if (Object.keys(accessToken).length) {
    return accessToken.token;
  }

  throw new Error('Invalid Access Token');
};

export const createResInfoFormData = (
  companyCode: string,
  reservationCode: string,
) => {
  const formData = new FormData();
  formData.append('companyCode', companyCode);
  formData.append('reservationCode', reservationCode);

  return formData;
};
