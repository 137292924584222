import useLocalization from '@localizations';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useTheme } from 'react-native-paper';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import Contact from './Contact';
import Language from './Language';
import Notifications from './Notifications';

export default function Profile() {
  const theme = useTheme();
  const { t } = useLocalization();
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const navState = [
    { key: 'contact', title: t('contact') },
    { key: 'notifications', title: t('notifications') },
    { key: 'language', title: t('language') },
  ];

  const renderScene = SceneMap({
    contact: Contact,
    notifications: Notifications,
    language: Language,
  });

  return (
    <TabView
      navigationState={{ index, routes: navState }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
      renderTabBar={props => (
        <TabBar
          {...props}
          style={{
            backgroundColor: theme.colors.primary,
          }}
          indicatorStyle={{ backgroundColor: theme.colors.background }}
          inactiveColor={`${theme.colors.background}88`}
        />
      )}
    />
  );
}
