import { Spacer } from '@components';
import AppConfig from '@config';
import getImage from '@images';
import {
  BannerStatus,
  bannerLabel,
  isRoomTypeCheckInRequested,
  reservationBannerStatus,
  trackEvent,
} from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { Company, Reservation, Theme } from '@store';
import { OpertoLogo } from '@svgs';
import { FunctionComponent } from 'react';
import {
  Image,
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  Headline,
  IconButton,
  Subheading,
  Text,
  useTheme,
} from 'react-native-paper';

// Function to detect if the user is on an iPhone using a web browser
const isIphoneOnWeb = () => {
  const userAgent = navigator.userAgent;
  return /iPhone|iPad/i.test(userAgent) && Platform.OS === 'web';
};

// Function to open the correct maps service
const openMaps = (address: string) => {
  let url = '';

  if (Platform.OS === 'ios' || isIphoneOnWeb()) {
    // Use Apple Maps on iOS and iPhone on web
    url = `http://maps.apple.com/?address=${encodeURIComponent(address)}`;
  } else {
    // Use Google Maps on Android and other platforms
    url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address,
    )}`;
  }

  // Open the appropriate maps app or web page
  Linking.openURL(url);
};

type AlertBannerProps = {
  title: string;
  onPress: () => void;
};

const AlertBanner = ({ title, onPress }: AlertBannerProps) => {
  return (
    <Pressable style={styles.banner} onPress={onPress}>
      <View style={AppConfig.Styles.rowContainer}>
        <IconButton
          style={styles.bannerIcon}
          icon="alert-circle"
          iconColor="white"
          size={AppConfig.Styles.getAdjustedSize(20)}
        />
        <Text
          numberOfLines={0}
          style={[AppConfig.Styles.caption, { color: 'white' }]}>
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

interface ICompanyViewProps {
  reservation: Reservation;
  company: Company;
  theme: Theme;
}
export const CompanyView: FunctionComponent<ICompanyViewProps> = ({
  reservation,
  theme,
  company,
}) => {
  const { t } = useLocalization();
  const navigation = useNavigation();
  const themeProvider = useTheme();
  const bannerStatus = reservationBannerStatus(company, reservation);

  const handleBannerClicked = (status: BannerStatus) => {
    switch (status) {
      case BannerStatus.VERIFICATION: {
        trackEvent('Global', 'Limited Access Banner:Clicked', {
          type: 'Verification',
        });
        navigation.navigate('Verification');
        break;
      }
      case BannerStatus.SECURITY_DEPOSIT: {
        navigation.navigate('SecurityDeposit');
        break;
      }
      default:
        break;
    }
  };

  const renderAlerts = () => {
    // render by priority
    if (bannerStatus !== BannerStatus.NONE) {
      return (
        <AlertBanner
          title={t(bannerLabel(bannerStatus))}
          onPress={() => handleBannerClicked(bannerStatus)}
        />
      );
    } else if (isRoomTypeCheckInRequested(reservation)) {
      return (
        <AlertBanner
          title={t('room_assignment_in_progress')}
          onPress={() => navigation.navigate('CheckInRequested')}
        />
      );
    }
  };

  return (
    <View style={[AppConfig.Styles.center, StyleSheet.absoluteFill]}>
      <View
        style={[
          AppConfig.Styles.center,
          styles.imageContainer,
          { borderColor: themeProvider.colors.primary },
        ]}>
        {theme?.enabled && theme?.dashboardLogoUrl ? (
          <Image
            style={styles.imageLogo}
            source={{
              uri: getImage(theme?.dashboardLogoUrl),
            }}
          />
        ) : (
          <OpertoLogo
            fill={themeProvider.colors.primary}
            width={AppConfig.Styles.getAdjustedSize(70)}
          />
        )}
      </View>
      <Spacer size={3} />
      <Headline style={styles.headerText} numberOfLines={2}>
        {reservation?.property?.name}
      </Headline>
      <Subheading style={styles.headerText}>
        <TouchableOpacity
          onPress={() => openMaps(reservation?.property?.address)}>
          <Text
            style={{
              color: 'inherit',
              textDecorationLine: 'underline',
              textAlign: 'center',
              paddingHorizontal: AppConfig.Styles.getAdjustedSize(10),
            }}>
            {reservation?.property?.address}
          </Text>
        </TouchableOpacity>
      </Subheading>

      {renderAlerts()}
    </View>
  );
};

interface IHomeHeaderProps {
  reservation: Reservation;
  company: Company;
  theme: Theme;
}
export const HomeHeader: FunctionComponent<IHomeHeaderProps> = ({
  theme,
  reservation,
  company,
}) => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.header,
        {
          backgroundColor: colors.primary,
          // @ts-ignore - this is not proper react-native way. we should try to not mix css and react-native
          backgroundImage: theme?.dashboardBackgroundUrl
            ? ` linear-gradient(rgba(0,0,0,.6), rgba(1,1,1,0)),url("${theme?.dashboardBackgroundUrl}") `
            : 'linear-gradient(rgba(0,0,0,.6), rgba(1,1,1,0));',
        },
      ]}>
      <CompanyView theme={theme} reservation={reservation} company={company} />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    height: AppConfig.Styles.getAdjustedSize(320),
    padding: AppConfig.Styles.getAdjustedSize(10),
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 600,
  },
  banner: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: AppConfig.Styles.getAdjustedSize(50),
    paddingVertical: AppConfig.Styles.getAdjustedSize(1),
    minHeight: AppConfig.Styles.getAdjustedSize(10),
    backgroundColor: AppConfig.Colors.WARNING,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
  },
  bannerIcon: {
    marginHorizontal: AppConfig.Styles.getAdjustedSize(0),
  },
  imageContainer: {
    width: AppConfig.Styles.getAdjustedSize(90),
    height: AppConfig.Styles.getAdjustedSize(90),
    borderRadius: AppConfig.Styles.getAdjustedSize(45),
    borderWidth: AppConfig.Styles.getAdjustedSize(3),
    backgroundColor: 'white',
    alignSelf: 'center',
  },
  imageLogo: {
    width: AppConfig.Styles.getAdjustedSize(80),
    height: AppConfig.Styles.getAdjustedSize(80),
    borderRadius: AppConfig.Styles.getAdjustedSize(40),
  },
  headerText: {
    textShadowColor: `${AppConfig.Colors.TERTIARY}88`,
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 5,
    zIndex: 1,
    color: 'white',
    textAlign: 'center',
  },
});

export default HomeHeader;
