import useLocalization from '@/assets/localizations';
import { logger } from '@/libs/logger';
import {
  WelcomeChallenge,
  dispatchReservations,
  useAppContext,
  useAppStore,
} from '@/store';
import { useAcceptWelcomeFlowMutation } from '@/store/reservations/api-slice';
import AppConfig from '@config';
import { useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { Button, Dialog, Portal, useTheme } from 'react-native-paper';

export const WelcomeDialog = () => {
  const { openSnackbar } = useAppContext();
  const { t } = useLocalization();
  const { company, reservation, theme } = useAppStore();
  const [visible, setVisible] = useState(true);
  const themeProvider = useTheme();
  const [updateReservation, { isLoading }] = useAcceptWelcomeFlowMutation();

  const handleContinue = async () => {
    try {
      await updateReservation({
        reservation_id: reservation?.reservationNumber,
      }).unwrap();

      if (reservation) {
        dispatchReservations([{ ...reservation, welcomeAccepted: true }]);
      }
      setVisible(false);
    } catch (e) {
      logger.error('Failed to update welcome_accepted', e);
      openSnackbar({
        title: 'Failed to update welcome accepted',
        color: themeProvider.colors.error,
      });
    }
  };

  return (
    <Portal>
      <Dialog visible={visible} style={styles.container} dismissable={false}>
        <View
          style={[
            AppConfig.Styles.center,
            styles.imageContainer,
            { borderColor: themeProvider.colors.primary },
          ]}>
          {theme?.welcomeLogoUrl && (
            <Image
              style={styles.imageLogo}
              source={{
                uri: theme.welcomeLogoUrl,
              }}
            />
          )}
        </View>
        <Dialog.Title style={styles.title}>
          {(company?.loginChallenges[2].data as WelcomeChallenge).title}
        </Dialog.Title>
        <Dialog.Content style={styles.contentWrapper}>
          <Text style={styles.content}>
            {(company?.loginChallenges[2].data as WelcomeChallenge).message}
          </Text>
        </Dialog.Content>
        <Dialog.Actions style={styles.continueActionButton}>
          <Button
            mode="contained"
            buttonColor="#0E0E0E"
            loading={isLoading}
            onPress={handleContinue}
            style={styles.continueButton}>
            {t('welcome_continue')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '90%',
    maxWidth: '500px',
    margin: '10px',
    padding: '24px',
    backgroundColor: 'white',
  },
  continueActionButton: {
    paddingBottom: '0',
    paddingRight: '0',
  },
  continueButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  contentWrapper: {
    paddingLeft: '0',
    paddingRight: '0',
    height: 'auto',
    maxHeight: '200px',
    overflow: 'scroll',
    marginBottom: '10px',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    marginLeft: '0',
    marginRight: '0',
    maxHeight: '100px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  imageLogo: {
    width: AppConfig.Styles.getAdjustedSize(80),
    height: AppConfig.Styles.getAdjustedSize(80),
    borderRadius: AppConfig.Styles.getAdjustedSize(40),
  },
  imageContainer: {
    width: AppConfig.Styles.getAdjustedSize(90),
    height: AppConfig.Styles.getAdjustedSize(90),
    borderRadius: AppConfig.Styles.getAdjustedSize(45),
    borderWidth: AppConfig.Styles.getAdjustedSize(3),
    backgroundColor: 'white',
    alignSelf: 'center',
  },
});
