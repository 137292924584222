import { logger } from '@/libs/logger';
import {
  useGetGuestUserQuery,
  usePatchGuestUserByIdMutation,
} from '@/store/services/userApi';
import { useLocalization } from '@localizations';
import { useNavigation, useRoute } from '@react-navigation/core';
import { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  Button,
  HelperText,
  MD3DarkTheme,
  MD3LightTheme,
  Text,
} from 'react-native-paper';
import OtpInput from '../../login/LoginCognito/OtpInput';
import { getCognitoAccessTokenFromAsyncStorage } from '../../login/LoginCognito/utils';
import PhoneNumberVerification from './PhoneNumberVerification';
import { CODE_LENGTH, COUNT_TO_RESEND_CODE } from './constants';
import { sendPhoneNumberVerificationCode, verifyPhoneNumber } from './utils';

const VerifyPhoneNumber = () => {
  const [otpCode, setOtpCode] = useState(Array(CODE_LENGTH).fill(''));
  const [accessToken, setAccessToken] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [countToResend, setCountToResend] = useState(0);

  const route = useRoute();
  const navigation = useNavigation();
  const { phoneNumber } = (route.params as { phoneNumber: string }) ?? {};
  const { t } = useLocalization();

  const { data: guest } = useGetGuestUserQuery();
  const [patchGuestUserById] = usePatchGuestUserByIdMutation();

  const renderOtpPad = () => {
    return (
      <View style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <OtpInput
          width={50}
          height={50}
          mode="outlined"
          contentStyle={{ minWidth: '100%' }}
          activeOutlineColor={MD3LightTheme.colors.primary}
          outlineColor={MD3DarkTheme.colors.outline}
          value={otpCode}
          onChange={value => {
            setOtpCode(value);
          }}
          outlineStyle={{ backgroundColor: 'transparent' }}
          error={showError}
        />

        <HelperText type="error" visible={!!showError} style={{ padding: 0 }}>
          {errorMsg}
        </HelperText>

        <Button
          mode="outlined"
          textColor="black"
          labelStyle={{
            marginHorizontal: 0,
          }}
          style={{
            height: 40,
            marginBottom: 16,
            marginTop: 16,
            marginHorizontal: 0,
            justifyContent: 'center',
            width: countToResend > 0 ? 150 : 130,
          }}
          onPress={onResendClick}
          disabled={countToResend > 0}>
          <Text
            variant="labelLarge"
            style={{
              color: countToResend > 0 ? 'grey' : MD3LightTheme.colors.primary,
            }}>
            {`${t('confirm_phone_number_resend_button')} ${
              countToResend ? `${countToResend}s` : ''
            }`}
          </Text>
        </Button>
      </View>
    );
  };

  const onResendClick = async () => {
    try {
      setCountToResend(COUNT_TO_RESEND_CODE);
      sendPhoneNumberVerificationCode(accessToken);
    } catch (e: unknown) {
      // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/GetUserAttributeVerificationCodeCommand/
      setErrorMsg((e as Error).message);
      setShowError(true);
    }
  };

  const verifyOtpCode = useCallback(
    async (code: string) => {
      try {
        const response = await verifyPhoneNumber(code, accessToken);

        if (response.$metadata.httpStatusCode === 200) {
          setOtpCode(Array(CODE_LENGTH).fill(''));

          if (guest?.id) {
            await patchGuestUserById({
              ...guest,
              phonePrimaryIsVerified: true,
            });
          }

          setShowError(false);
          setErrorMsg('');
          navigation.navigate('Reservations');
        } else {
          throw new Error('Failed to verify phone number');
        }
      } catch (e: unknown) {
        setOtpCode(Array(CODE_LENGTH).fill(''));

        // Check if e is ExpiredCodeException
        // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/cognito-identity-provider/command/VerifyUserAttributeCommand/
        if ((e as Error).name === 'ExpiredCodeException') {
          setErrorMsg(t('confirm_phone_number_resend_expired'));
          setShowError(true);
        } else {
          setErrorMsg(t('confirm_phone_number_resend_error'));
        }
        setShowError(true);
      }
    },
    [accessToken, guest, navigation, patchGuestUserById, t],
  );

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getCognitoAccessTokenFromAsyncStorage();
        setAccessToken(token ?? '');
      } catch (e) {
        logger.error(e);
      }
    };

    getAccessToken();
  }, []);

  useEffect(() => {
    if (countToResend) {
      setTimeout(() => {
        setCountToResend(prevCount => prevCount - 1);
      }, 1000);
    }
  }, [countToResend]);

  useEffect(() => {
    const code = otpCode.join('');
    if (code.length === CODE_LENGTH) {
      verifyOtpCode(code);
    }
  }, [otpCode, verifyOtpCode]);

  return (
    <PhoneNumberVerification
      title={t('add_phone_number_header')}
      subtitle={`${t('confirm_phone_number_body')} ${phoneNumber}`}
      headerType="previous"
      onHeaderAction={() => {
        navigation.navigate('AddPhoneNumber');
      }}>
      {renderOtpPad()}
    </PhoneNumberVerification>
  );
};

export default VerifyPhoneNumber;
