import { CustomChannelHeader, INavigationParamsProps } from '@components';
import AppConfig from '@config';
import { useRoute } from '@react-navigation/native';
import {
  Member,
  dispatchBroadcast,
  dispatchChatNotify,
  useAppStore,
} from '@store';
import '@stream-io/stream-chat-css/dist/css/index.css';
import { useEffect } from 'react';
import { View, useWindowDimensions } from 'react-native';
import { useTheme } from 'react-native-paper';
import { Channel as ChannelType, StreamChat } from 'stream-chat';
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';
import Layout from '../components/Layout';
import RootContainer from '../components/RootContainer';
import './messages.css';

const chatClient = StreamChat.getInstance(
  AppConfig.Settings.GET_STREAM_API_KEY as string,
);

const Messages = () => {
  const { host, messagingToken, reservation, company } = useAppStore();
  const route = useRoute();
  const theme = useTheme();
  const params = route.params as INavigationParamsProps;
  const text = params?.text;
  const autoReply = params?.autoReply;
  const member = (host?.members as Member[]).find(Boolean);
  const people = member?.id;
  const userToken = messagingToken?.token;
  let channel: ChannelType | undefined;
  const { height } = useWindowDimensions();
  const HEIGHT_OFFSET = height - 60;

  useEffect(() => {
    if (text && channel?.id && company) {
      channel.sendMessage({
        text,
      });

      dispatchChatNotify(text, company.id, company.reservation as string);
    }
  }, [channel, text, company]);

  useEffect(() => {
    if (company && autoReply) {
      dispatchBroadcast(autoReply, company.id, company.reservation as string);
    }
  }, [autoReply, company]);

  // TODO: revisit this. I think complains when you look at the browser console
  // something about connectUser called multiple times
  if (messagingToken?.id) {
    chatClient.connectUser(
      {
        id: messagingToken?.id || '',
        name: reservation?.guestName || 'Guest',
        image: '',
      },
      userToken,
    );
    channel = chatClient.channel(
      `${messagingToken?.channel_id.split(':')[0]}`,
      `${messagingToken?.channel_id.split(':')[1]}`,
      {
        // add as many custom fields as you'd like
        image: `${host?.welcomeAvatar}`,
        name: `${member?.name}`,
        members: [`${people}`],
      },
    );
  }

  return (
    <RootContainer>
      <Layout>
        <View style={{ height: HEIGHT_OFFSET }}>
          <Chat
            client={chatClient}
            theme="messaging light"
            customStyles={{
              '--primary-color': theme.colors.primary,
            }}>
            <Channel channel={channel}>
              <Window>
                <CustomChannelHeader
                  source={host?.welcomeAvatar ?? ''}
                  name={member?.name ?? ''}
                />
                <MessageList />
                <MessageInput />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </View>
      </Layout>
    </RootContainer>
  );
};

export default Messages;
